import React from 'react';

const Preloader = () => {
    return (
        <>
            <div className="catalog-item">
                <div className="catalog-avatar">
                    <div class="catalog-avatar-load"></div>
                </div>
                <div className="catalog-content">
                    <div className="catalog-title">
                        <div className="skeleton"></div>
                    </div>
                    <div className="catalog-description">
                        <div className="skeleton"></div>
                    </div>
                </div>
                <div className="catalog-button">
                    <div className="skeleton"></div>
                </div>
            </div>
            <div className="catalog-item">
                <div className="catalog-avatar">
                    <div class="catalog-avatar-load"></div>
                </div>
                <div className="catalog-content">
                    <div className="catalog-title">
                        <div className="skeleton"></div>
                    </div>
                    <div className="catalog-description">
                        <div className="skeleton"></div>
                    </div>
                </div>
                <div className="catalog-button">
                    <div className="skeleton"></div>
                </div>
            </div>
            <div className="catalog-item">
                <div className="catalog-avatar">
                    <div class="catalog-avatar-load"></div>
                </div>
                <div className="catalog-content">
                    <div className="catalog-title">
                        <div className="skeleton"></div>
                    </div>
                    <div className="catalog-description">
                        <div className="skeleton"></div>
                    </div>
                </div>
                <div className="catalog-button">
                    <div className="skeleton"></div>
                </div>
            </div>
            <div className="catalog-item">
                <div className="catalog-avatar">
                    <div class="catalog-avatar-load"></div>
                </div>
                <div className="catalog-content">
                    <div className="catalog-title">
                        <div className="skeleton"></div>
                    </div>
                    <div className="catalog-description">
                        <div className="skeleton"></div>
                    </div>
                </div>
                <div className="catalog-button">
                    <div className="skeleton"></div>
                </div>
            </div>
            <div className="catalog-item">
                <div className="catalog-avatar">
                    <div class="catalog-avatar-load"></div>
                </div>
                <div className="catalog-content">
                    <div className="catalog-title">
                        <div className="skeleton"></div>
                    </div>
                    <div className="catalog-description">
                        <div className="skeleton"></div>
                    </div>
                </div>
                <div className="catalog-button">
                    <div className="skeleton"></div>
                </div>
            </div>
            <div className="catalog-item">
                <div className="catalog-avatar">
                    <div class="catalog-avatar-load"></div>
                </div>
                <div className="catalog-content">
                    <div className="catalog-title">
                        <div className="skeleton"></div>
                    </div>
                    <div className="catalog-description">
                        <div className="skeleton"></div>
                    </div>
                </div>
                <div className="catalog-button">
                    <div className="skeleton"></div>
                </div>
            </div>
            <div className="catalog-item">
                <div className="catalog-avatar">
                    <div class="catalog-avatar-load"></div>
                </div>
                <div className="catalog-content">
                    <div className="catalog-title">
                        <div className="skeleton"></div>
                    </div>
                    <div className="catalog-description">
                        <div className="skeleton"></div>
                    </div>
                </div>
                <div className="catalog-button">
                    <div className="skeleton"></div>
                </div>
            </div>
            <div className="catalog-item">
                <div className="catalog-avatar">
                    <div class="catalog-avatar-load"></div>
                </div>
                <div className="catalog-content">
                    <div className="catalog-title">
                        <div className="skeleton"></div>
                    </div>
                    <div className="catalog-description">
                        <div className="skeleton"></div>
                    </div>
                </div>
                <div className="catalog-button">
                    <div className="skeleton"></div>
                </div>
            </div>
        </>
    );
}

export default Preloader;