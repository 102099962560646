import React from 'react';
import CatalogItem from '../../../components/catalog';
import CategoryItem from '../../../components/category';
import Lottie from "lottie-react";
import DuckAnimation from "../../../animations/duck.json";
import Preloader from '../../../components/preloader';
import Pagination from '../../../components/pagination';
import { Waypoint } from 'react-waypoint';

const CatalogFragment = ({
    isFetching,
    scrollToggle,
    useTranslation,
    categoriesList,
    appsList,
    currentPage,
    currentTab,
    setCurrentTab,
    setScrollToggle,
    setCategoryText,
    setSortData,
    setCleanData,
    setCurrentPage,
    setToggleFetching,
}) => {

    const { t } = useTranslation();

    const categoryElements = categoriesList.map((category, index) => 
        <CategoryItem
            key={index}
            useTranslation={useTranslation}
            id={category.id}
            type={category.type}
            sort={category.sort}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            setCategoryText={setCategoryText}
            setSortData={setSortData}
            setCleanData={setCleanData}
            setToggleFetching={setToggleFetching}
        />
    );

    const catalogElements = appsList.map((app, index) => 
        <CatalogItem
            key={index}
            useTranslation={useTranslation}
            avatar={app.avatar}
            name={app.name}
            short_description={app.short_description}
            short_description_ru={app.short_description_ru}
            is_trending={app.is_trending}
            is_new={app.is_new}
            is_verified={app.is_verified}
            link={app.link}
        />
    );

    const handleIncreasePage = () => {
        if(scrollToggle) {
            setScrollToggle(false);
            setCurrentPage(currentPage + 1);
        }
    }

    return (
        <>
        <div className="categories">
            <div className="categories-items">
                {categoryElements}
            </div>
        </div>
        {isFetching ? (
            <div className="catalog">
                <Preloader />
            </div>
        ) : (
            <>
            {appsList.length === 0 ? (
                <div className="results">
                    <div className="results-animation">
                        <Lottie animationData={DuckAnimation} loop={true} />
                    </div>
                    <div className="results-title">
                        <span>{t(`other.no_results`)}</span>
                    </div>
                    <div className="results-description">
                        <span>{t(`other.no_results_description`)}</span>
                    </div>
                </div>
            ) : (
                <>
                    <div className="catalog">
                        {catalogElements}
                    </div>
                    {scrollToggle && (
                        <Pagination />
                    )}
                    <Waypoint onEnter={() => handleIncreasePage()} />
                </>
            )} 
            </>
        )}
        </>
    );
}

export default CatalogFragment;