const SET_APPS = 'SET-APPS';
const SET_SEARCH = 'SET-SEARCH';
const SET_TOGGLE_FETCHING = 'SET_TOGGLE_FETCHING';
const SET_CLEAN_DATA = 'SET-CLEAN-DATA';

let initState = {
    isFetching: true,
    categoriesList: [
        {
            id: 0,
            type: '',
            sort: 'created_at:desc',
        },
        {
            id: 1,
            type: '',
            sort: 'created_at:asc',
        },
        {
            id: 2,
            type: 'wallets',
            sort: 'created_at:asc',
        },
        {
            id: 3,
            type: 'exchanges',
            sort: 'created_at:asc',
        },
        {
            id: 4,
            type: 'marketplaces',
            sort: 'created_at:asc',
        },
        {
            id: 5,
            type: 'games',
            sort: 'created_at:asc',
        },
        {
            id: 6,
            type: 'staking',
            sort: 'created_at:asc',
        },
        {
            id: 7,
            type: 'social',
            sort: 'created_at:asc',
        },
        {
            id: 8,
            type: 'tools',
            sort: 'created_at:asc',
        },
        {
            id: 9,
            type: 'other',
            sort: 'created_at:asc',
        },
    ],
    appsList: []
};

const homeReducer = (state = initState, action) => {

    switch(action.type) {
        default:
            return state;

        case SET_APPS: 
        
            const appsData = action.data.map(app => app.document);
            const filteredAppsData = appsData.filter(newApp => !state.appsList.some(existingApp => existingApp.id === newApp.id));
            
            return {
                ...state,
                appsList: [...state.appsList, ...filteredAppsData],
            };

        case SET_SEARCH: 
        
            const searchData = action.data.map(app => app.document);
            return {
                ...state,
                appsList: searchData,
            };

        case SET_TOGGLE_FETCHING: 
            return {
                ...state,
                isFetching: action.data,
            };

        case SET_CLEAN_DATA: 
            return {
                ...state,
                appsList: [],
            };
    }

}

export const setApps = (data) => {
    return {
        type: SET_APPS,
        data
    }
}

export const setSearch = (data) => {
    return {
        type: SET_SEARCH,
        data
    }
}

export const setToggleFetching = (data) => {
    return {
        type: SET_TOGGLE_FETCHING,
        data
    }
}

export const setCleanData = () => {
    return {
        type: SET_CLEAN_DATA
    }
}

export default homeReducer;