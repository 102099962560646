import React from 'react';

const CatalogItem = ({
    avatar,
    name,
    useTranslation,
    short_description,
    short_description_ru,
    is_trending,
    is_new,
    is_verified,
    link,
}) => {

    const { t } = useTranslation();

    const storage = process.env.REACT_APP_IMAGE_ENDPOINT;

    const handleClickLink = () => {
        const mq = window.matchMedia('(max-width: 480px)');
        if (mq.matches) {
          window.open(link, '_blank');
        }
    };

    return (
        <div className="catalog-item" onClick={() => handleClickLink()}>
            <div className="catalog-avatar">
                <div className="catalog-icon">
                    <img src={`${storage}${avatar}`} alt={name} />
                </div>
            </div>
            <div className="catalog-content">
                <div className="catalog-header">
                    <div className="catalog-title">
                        <span>{name}</span>
                    </div>
                    {is_verified && !is_trending ? (
                        <div className="catalog-verify">
                            <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 7C14 6.02341 13.399 5.17659 12.5044 4.71902C12.8185 3.76976 12.641 2.73854 11.9512 2.04878C11.2615 1.35902 10.2302 1.18146 9.28098 1.49561C8.83024 0.600976 7.97659 0 7 0C6.02341 0 5.17659 0.600976 4.72585 1.49561C3.76976 1.18146 2.73854 1.35902 2.04878 2.04878C1.35902 2.73854 1.18829 3.76976 1.50244 4.71902C0.607805 5.17659 0 6.02341 0 7C0 7.97659 0.607805 8.82341 1.50244 9.28098C1.18829 10.2302 1.35902 11.2615 2.04878 11.9512C2.73854 12.641 3.76976 12.8117 4.71902 12.5044C5.17659 13.399 6.02341 14 7 14C7.97659 14 8.83024 13.399 9.28098 12.5044C10.2302 12.8117 11.2615 12.641 11.9512 11.9512C12.641 11.2615 12.8185 10.2302 12.5044 9.28098C13.399 8.82341 14 7.97659 14 7ZM5.47094 9.33631L3.93195 7.79732C3.66483 7.53019 3.66406 7.09734 3.93024 6.82927C4.19521 6.56242 4.62633 6.5609 4.89318 6.82587L4.89488 6.82756L5.7337 7.66638C5.85086 7.78354 6.04081 7.78354 6.15796 7.66638C6.16099 7.66335 6.16396 7.66026 6.16686 7.6571L8.77079 4.8199C9.02514 4.54277 9.45599 4.5243 9.73312 4.77864L9.73512 4.78049C10.0128 5.03734 10.0307 5.47025 9.77523 5.74918L6.51104 9.31347C6.24248 9.60672 5.78704 9.62673 5.49379 9.35817C5.48601 9.35105 5.4784 9.34376 5.47094 9.33631Z" fill="currentColor"></path>
                            </svg>
                        </div>
                    ) : ''}
                    {is_trending && (
                        <div className="catalog-badge catalog-badge-hot">
                            <span>HOT</span>
                        </div>
                    )}
                </div>
                <div className="catalog-description">
                    {t('locale') === 'ru' ? (
                        <span>{short_description_ru}</span>
                    ) : (
                        <span>{short_description}</span>
                    )}
                </div>
            </div>
            <div className="catalog-button">
                <a href={link} target="_blank" rel="noreferrer" className="button">
                    <span>{t(`other.open`)}</span>
                </a>
            </div>
        </div>
    );
}

export default CatalogItem;