import axios from "axios";

const TYPESENSE_URL = process.env.REACT_APP_API_ENDPOINT;
const TYPESENSE_KEY = process.env.REACT_APP_API_KEY;

export const getApps = (searchText, currentPage, sortData) => {
    return axios.get(`${TYPESENSE_URL}/collections/projects/documents/search`, {
        params: {
            q: searchText,
            page: currentPage,
            per_page: 20,
            sort_by: sortData,
            query_by: 'name,tags,category_slug',
            'x-typesense-api-key': TYPESENSE_KEY
        },
        headers: {
            'Content-Type': 'application/json',
            'x-typesense-api-key': TYPESENSE_KEY
        }
    });
};
