import React from 'react';

const Pagination = () => {
    return (
        <div className="pagination">
            <div className="pagination-container">
                <div className="pagination-preloader">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="14" r="35" strokeDasharray="164.93361431346415 56.97787143782138"></circle>
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default Pagination;