import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../components/header';
import CatalogFragment from './components/catalogFragment';
import { connect } from 'react-redux';
import { getApps } from '../../api/typesense';
import { setApps, setSearch, setCleanData, setToggleFetching } from '../../redux/reducers/homeReducer';

const Home = ({
    isFetching,
    useTranslation,
    categoriesList,
    appsList,
    setApps,
    setSearch,
    setCleanData,
    setToggleFetching
}) => {

    const { t } = useTranslation();

    const [searchText, setSearchText] = useState('');
    const [categoryText, setCategoryText] = useState('');
    const [sortData, setSortData] = useState('created_at:asc');
    const [scrollToggle, setScrollToggle] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentTab, setCurrentTab] = useState(1);

    const apiGetSearch = useCallback(async () => {
        try {
            const response = await getApps(categoryText, 1, sortData);
            const results = await response;
            setSearch(results.data.hits);
            setCurrentPage(1);
            if (results.data.hits.length === 20) {
                setScrollToggle(true);

            } else {
                setScrollToggle(false); 
            }
        } catch (error) {
            console.log(error);
        } finally {
            setToggleFetching(false);
        }
    }, [categoryText, setSearch, setCurrentPage, sortData, setToggleFetching]);

    const apiGetApps = useCallback(async () => {
        try {
            const response = await getApps(categoryText, currentPage, sortData);
            const results = await response;
            setApps(results.data.hits);
            if (results.data.hits.length === 20) {
                setScrollToggle(true);
  
            } else {
                setScrollToggle(false); 
            }
        } catch (error) {
            console.log(error);
        } finally {
            setToggleFetching(false);
        }
    }, [currentPage, categoryText, setApps, sortData, setToggleFetching]);

    useEffect(() => {
        apiGetApps();
    }, [apiGetApps]);

    useEffect(() => {
        apiGetSearch();
    }, [apiGetSearch]);

    return (
        <>
            <Header 
                searchText={searchText}
                categoryText={categoryText}
                useTranslation={useTranslation}
                setSearchText={setSearchText}
                setCategoryText={setCategoryText}
            />
            <div className="content">
                <div className="mid">
                    <div className="title">
                        <h1>{t(`other.title`)} <span className="title-gradient">The Open Network</span></h1>
                    </div>
                    <CatalogFragment
                        isFetching={isFetching}
                        scrollToggle={scrollToggle}
                        useTranslation={useTranslation}
                        categoriesList={categoriesList}
                        appsList={appsList}
                        currentPage={currentPage}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        setScrollToggle={setScrollToggle}
                        setCurrentPage={setCurrentPage}
                        setToggleFetching={setToggleFetching}
                        setCategoryText={setCategoryText}
                        setSortData={setSortData}
                        setCleanData={setCleanData}
                    />
                </div>
            </div>
        </>
    );
}

let mapStateToProps = (state) => {
    return {
        isFetching: state.home.isFetching,
        categoriesList: state.home.categoriesList,
        appsList: state.home.appsList,
    }
}

export default connect(mapStateToProps, {
    setApps,
    setSearch,
    setCleanData,
    setToggleFetching
})(Home);
