import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ruTranslation from './ru.json';
import enTranslation from './en.json';


let setLanguage = 'ru';

const lang = window.Telegram.WebApp.initData;

if (lang) {
    
    const decodedData = decodeURIComponent(lang);
    const regex = /user=({.*?})&/;
    const match = decodedData.match(regex);
    const userLanguage = JSON.parse(match[1]);

    if (match) {
        if (userLanguage.language_code === 'ru') {
            setLanguage = 'ru';
        } else {
            setLanguage = 'en';
        }
    } else {
        setLanguage = 'en';
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources: {
            ru: {
                translation: ruTranslation
            },
            en: {
                translation: enTranslation
            }
        },
        lng: setLanguage,
        fallbackLng: 'ru',
        interpolation: {
            escapeValue: false
        }
    });


