
const CategoryItem = ({
    useTranslation,
    id,
    type,
    sort,
    currentTab,
    setCategoryText,
    setSortData,
    setCleanData,
    setCurrentTab,
    setToggleFetching,
}) => {

    const { t } = useTranslation();

    const handleSelectCategory = () => {
        setCleanData();
        setCategoryText(type);
        setSortData(sort);
        setCurrentTab(id);
        setToggleFetching(true);
    }

    return (
        <div
            className={`categories-button ${currentTab === id ? 'active' : ''}`} 
            onClick={currentTab !== id ? () => handleSelectCategory() : null}
        >
            <span>{t(`categories.${id}`)}</span>
        </div>
    );
}

export default CategoryItem;