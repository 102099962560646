import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Home from './scenes/Home';
import NotFound from './scenes/404';

const App = ({
    store
}) => {
    
    useEffect(() => {
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.ready();
        setupTheme();
    }, []);

    function setupTheme() {
        const themeData = window.Telegram.WebApp.themeParams;
        // Временный фикс темы на ios
        if (window.Telegram.WebApp.platform === 'ios' || window.Telegram.WebApp.platform === 'tdesktop') {
            window.Telegram.WebApp.setHeaderColor(themeData.section_bg_color)
            window.Telegram.WebApp.setBackgroundColor(themeData.section_bg_color)
            document.documentElement.style.setProperty('--tg-theme-bg-color', themeData.section_bg_color);
        }
    }

    return (
        <div className="main">
            <Routes>
                <Route path="/" element={ <Home store={store} useTranslation={useTranslation} /> } />
                <Route path="*" element={ <NotFound store={store} useTranslation={useTranslation} />} />
            </Routes>
            
        </div>
        
    );
}

let mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps, {
    
})(App);
